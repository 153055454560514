import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import API, { PricingURL } from "commons/API";
import { CART_TYPES } from "store/types";

export function usePaymentPeriod() {
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const dispatch = useDispatch();
  const { paymentPeriod } = useSelector((state) => state.cart);

  const selectedPeriod = useMemo(() => {
    const regexFindText = new RegExp(`${paymentPeriod.name}`, "gi");
    const getData = [...list].reduce((acc, period) => {
      if (period.name.search(regexFindText) > -1) {
        acc = period;
        dispatch({ type: CART_TYPES.CHANGE_PAYMENT_PERIOD, paymentPeriod: acc });
      }

      return acc;
    }, {});

    return getData;
  }, [dispatch, list, paymentPeriod.name]);

  const handlePaymentPeriodChange = (paymentPeriod) => {
    dispatch({ type: CART_TYPES.CHANGE_PAYMENT_PERIOD, paymentPeriod });
  };

  useEffect(() => {
    (async function getData() {
      setLoading(true);

      try {
        const fetchAPI = await API(PricingURL.GetListPaymentPeriod);
        const { billing_types } = await fetchAPI.data;

        setList(billing_types);
      } catch (err) {
        setLoading(false);
        if (err?.response?.status !== 404) throw new Error(err);
      }

      setLoading(false);
    })();
  }, []);

  return { isLoading, list, selectedPeriod, handlePaymentPeriodChange };
}
