import React from "react";

import Layout from "components/Layout";
import SEO from "components/seo";
import CartView from "domains/Cart";

export default function Cart(props) {
  return (
    <>
      <SEO title="Cart" />
      <Layout location={props.location}>
        <CartView {...props} />
      </Layout>
    </>
  );
}
