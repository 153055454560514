import styled, { css } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'

export const WrapperQuantity = styled.div`
  display: grid;
  justify-content: center;
  grid-template-areas:
    ". title ."
    "minButton qty plusButton"
    ". unitName .";

  ${({ unitPosition }) => unitPosition === 'right' &&
    css`
      grid-template-areas: "minButton qty plusButton unitName";
    `}

  ${({ disabled }) => disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

export const Title = styled.p`
  grid-area: title;
  margin: 0 0.9em;
  font-size: 1em;
`

export const MinButton = styled.p`
  grid-area: minButton;
  margin: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.5px solid ${darkBlueGrey};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) => disabled &&
    css`
      cursor: not-allowed;
    `}

  span {
    font-size: calc(100% * 2);
    margin-top: -5px;
  }
`

export const PlusButton = styled(MinButton)`
  grid-area: plusButton;

  span {
    font-size: calc(100% * 1.2);
    margin-top: -3px;
  }
`

export const Quantity = styled.div`
  grid-area: qty;
  grid-auto-flow: row;
  text-align: center;
  border-bottom: 1px solid ${darkBlueGrey};
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};

  ${({ hideTitle }) => hideTitle &&
    css`
      width: 3em;
    `}
`

export const UnitName = styled.p`
  margin: 0;
  grid-area: unitName;
  font-size: 1.1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0.3em;

  ${({ unitPosition }) => unitPosition === 'right' &&
    css`
      margin-top: 0;
      align-self: center;
      font-size: 1.4em;
      margin-left: 0.5em;
    `}
`
