import styled, { css } from 'styled-components'
import { 
  snow, 
  greenColor, 
  coolGrey, 
  darkBlueGrey 
} from 'components/baseColor'
import { 
  IS_FRAME_MOBILE, 
  IS_FRAME_TABLET 
} from 'utils'


// TODO: please check the root cause why the SubTotal color was override by SaveAnually color when user reload the page in stg & prod env

export const Wrapper = styled.div`
  right: 0;
  width: 28em;
  height: 100vh;
  background-color: ${snow};
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.25);
  padding: 0.7rem 2rem;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-row-gap: 1.1em;
  position: sticky;
  top: 5rem;

  .save-annually__text {
    color: ${greenColor};
    font-weight: bold;
    font-size: 1.15em;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .subtotal__text {
    color: ${coolGrey} !important;
    text-transform: uppercase;
    font-size: 1.19em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .subtotal__amount {
    margin: 18px 0; 
    font-weight: bold; 
    font-size: 2em;
    text-align: center;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }

  > p {
    margin: 0;
  }

  ${IS_FRAME_TABLET} {
    height: max-content;
    bottom: 0;
    left: 0;
    width: calc(100vw - 34px);
    padding: 2rem 1rem;
    box-shadow: 10px 0 25px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #222b495e;
    margin-top: 0;
  }

  ${IS_FRAME_MOBILE} {
    height: max-content;
    bottom: 0;
    left: 0;
    width: calc(100vw - 34px);
    padding: 1rem;
    box-shadow: 10px 0 25px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #222b495e;
    margin-top: 0;
  }
`

Wrapper.WrapperLeftSide = styled.div`
  justify-self: flex-start;
`

export const Title = styled.p`
  font-size: 2.3em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  text-transform: uppercase;

  ${IS_FRAME_MOBILE} {
    font-size: 2rem;
  }
`

export const Hr = styled.hr`
  height: 0px;
  background-color: #0000005c;
  width: 100%;
  overflow: none;
  margin: 0;
`

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize || '1.15em'};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  margin: ${({ m }) => m};
  margin-top: ${({ mt }) => mt};

  ${({ shaded }) => shaded &&
    css`
      color: ${coolGrey};
      text-transform: uppercase;
      font-size: 1.19em;
    `}

  ${({ bold }) => bold &&
    css`
      font-weight: bolder;
    `}
`

export const Ul = styled.ul`
  height: calc(100vh - 512px);
  overflow: auto;
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
  padding-right: 6px;
`

export const Li = styled.li`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`

export const WrapperMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WrapperSavedMoney = styled.div`
  text-align: center;
`
