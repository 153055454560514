import React from 'react'
import { createPortal } from 'react-dom'
import { XSign } from '@qlue/icon-kit'
import { navigate } from 'gatsby'

import Button from 'components/Button'

import { Wrapper, DialogLine, Dialog, DialogTitle, DialogDesc, DialogBody } from './styles'
import { isBrowser } from 'utils'

const AuthWall = ({ handleClose }) => {
  const href = isBrowser ? location.href : ''
  const dashboardURL = `${process.env.GATSBY_DASHBOARD_URL}/login?redirect_to=${href}`

  const onLoginClick = () => {
    if (isBrowser) window.location.href = dashboardURL
  }

  if (isBrowser) {
    return createPortal(
      <Wrapper>
        <Dialog>
          <XSign onClick={ handleClose } />
          <DialogBody>
            <DialogTitle>LOGIN TO CHECKOUT</DialogTitle>
            <DialogDesc>Please, login to your account to checkout</DialogDesc>
            <Button onClick={ onLoginClick } buttonType='warning' width='100%'>
              LOGIN
            </Button>
            <DialogLine>or</DialogLine>
            <Button
              buttonType='primary'
              onClick={ () => navigate(`/register?next_link=${href}`) } 
              width='100%'
            >
              CREATE ACCOUNT
            </Button>
          </DialogBody>
        </Dialog>
      </Wrapper>,
      document.querySelector('#___gatsby')
    )
  } else return <React.Fragment />
}

export default AuthWall
