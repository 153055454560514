import React, { useState, useEffect } from 'react'
import { WrapperCheckout, Button } from './styles'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

const CheckoutBtn = ({ handleClickCheckout }) => {
  const intl = useIntl()
  const { selectedPackage } = useSelector((state) => state.cart)
  const [isDisabled, setIsDisabled] = useState(false)

  const keysProduct = Object.keys(selectedPackage.products)

  useEffect(() => {
    setIsDisabled(!keysProduct.length)
  }, [keysProduct.length])

  return(
    <WrapperCheckout>
      <Button 
        height='2.5rem' 
        onClick={ handleClickCheckout }
        width='100%' 
        type='warning'
        disabled={ isDisabled }
      >
        { intl.formatMessage({ id: '279f61482' }) }
      </Button>
    </WrapperCheckout>
  )
}

export default CheckoutBtn
