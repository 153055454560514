import styled from "styled-components";
import { darkBlueGrey } from "components/baseColor";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const Dialog = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #bcbfc8;
  background-color: #fff;
  padding: 16px;
  display: grid;
  grid-auto-flow: row;
  text-align: center;

  > svg {
    justify-self: flex-end;
    cursor: pointer;

    > path {
      fill: ${darkBlueGrey};
    }
  }
`;

export const DialogTitle = styled.div`
  font-size: 1.7em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
`;

export const DialogDesc = styled.div`
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: ${darkBlueGrey};
  margin: 1em 0 2em;
`;

export const DialogBody = styled.div`
  padding: inherit;
  padding-top: 0;
`;

export const DialogLine = styled.div`
  color: ${darkBlueGrey};
  font-size: 1.2em;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 1.5em 0;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${darkBlueGrey};
    margin-right: 0.8em;
  }

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${darkBlueGrey};
    margin-left: 0.8em;
  }
`;
