import styled, { css } from 'styled-components'

import { snow, darkBlueGrey, mykonosBlue, greenColor } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  grid-column-gap: 0.75rem;
  min-height: calc(100vh - 8em);

  .save-annually__text {
    color: ${greenColor};
    font-weight: bold;
    font-size: 1.15em;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  ${IS_FRAME_TABLET} {
    display: flex;
    flex-direction: column;
    min-height: unset;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
    min-height: unset;
  }
`

export const WrapperPlanSection = styled.div`
  padding: 3rem 2rem;
  height: inherit;
  padding-left: 3em;
  padding-bottom: 0;
  margin-bottom: 5rem;

  ${IS_FRAME_MOBILE} {
    padding: 1rem;
    margin-bottom: 2rem;
  }
`

export const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: space-between;
  margin-bottom: 1.5em;

  > p {
    margin: 0;
  }
`

export const WrapperCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(10em, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 1rem;

  ${({ isCustom }) => !isCustom &&
    css`
      margin-top: 1em;
    `}

  ${IS_FRAME_TABLET} {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
`

export const Card = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  border-radius: 4px;
  border: solid 1px ${mykonosBlue};
  background-color: ${snow};
  padding: 16px 12px;

  ${({ disabled }) => disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

Card.Title = styled.p`
  background-color: ${mykonosBlue};
  margin: -16px -12px;
  padding: 16px 12px;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;

  ${IS_FRAME_MOBILE} {
    padding: 0.5rem;
  }
`

Card.Body = styled.div`
  position: relative;
  padding-top: 16px;

  ${IS_FRAME_MOBILE} {
    padding-top: 0.3rem;
  }
`

Card.Name = styled.p`
  font-size: 1.3em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
  }
`

Card.Text = styled.p`
  margin: 0;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  font-size: 0.925rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey} !important;
  text-align: ${({ textAlign }) => textAlign || 'left'};

  p {
    font-size: 0.875rem !important;
  }

  ${({ bold }) => bold &&
    css`
      font-weight: bolder;
    `}
`

Card.WrapperAdd = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`

Card.Gap = styled.div`
  margin-top: 5em;
`

Card.WrapperQtyButton = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
`

Card.WrapperTrashIcon = styled.div`
  margin-top: 1.6em;
  cursor: pointer;

  > svg {
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    > path {
      fill: ${darkBlueGrey};
    }
  }
`

export const WrapperStorageCapacity = styled.div`
  display: flex;
`
