import { useState, useEffect } from "react";
import API, { PricingURL } from "commons/API";

export function useProducts() {
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    (async function getData() {
      setLoading(true);

      try {
        const fetchAPI = await API(PricingURL.GetListProducts);
        const { products } = await fetchAPI.data;

        setList(products);
      } catch (err) {
        setLoading(false);
        if (err?.response?.status !== 404) throw new Error(err);
      }

      setLoading(false);
    })();
  }, []);

  return { isLoading, list };
}
