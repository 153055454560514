import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { IconTrash } from '@qlue/icon-kit'
import Select from 'components/Select'
import Button from 'components/Button'
import QuantityButton from 'components/QuantityButton'
import Shimmer from 'components/Shimmer'
import MobileOnly from 'components/MobileOnly'
import {
  Wrapper,
  Card,
  WrapperCard,
  Header,
  WrapperPlanSection,
  WrapperStorageCapacity
} from './styles'
import { CART_TYPES } from 'store/types'
import { Title, Hr, Text } from './YourCart/styles'
import YourCart from './YourCart'
import { formatMoney, IS_FRAME_MOBILE_JS } from 'utils'
import { useProducts } from './models/useProducts'
import { usePaymentPeriod } from './models/usePaymentPeriod'
import { useHelpers } from './models/useHelpers'
import { useIntl } from 'react-intl'


const USER_SUBSCRIPTION = {
  name  : 'Mobile Field Officers', // User Subscription OR Mobile Field Officers
  id    : 'ba6f00db-12ad-4dba-a1ad-cfe022f82a1f',
  alias : 'qluework'
}

export default function CartView() {
  const dispatch = useDispatch()
  const { 
    isAnnually,
    packages, 
    selectedPackage, 
    isCustom, 
    ...cart 
  } = useSelector((state) => state.cart)
  const products = useProducts()
  const paymentPeriod = usePaymentPeriod()
  const helpers = useHelpers()
  const intersectionObsrv = useRef()
  const cartRef = useRef()
  const bottomList = useRef()

  const [isShowSaveAnnually, setIsShowSaveAnnualy] = useState(true)

  const intl = useIntl()

  const callbackIntersectionObserver = (entries) => {
    const bottomListEle = entries[0]
    const eleCart = cartRef.current

    if (bottomListEle.isIntersecting) {
      eleCart.style.position = 'unset'
      eleCart.style.marginTop = '-2rem'
    } else eleCart.style.position = 'sticky'
  }

  useEffect(() => {

    if(!selectedPackage.name) navigate('/pricing')

    if (IS_FRAME_MOBILE_JS) {
      const configs = { rootMargin: '0% 0% -9% 0%' }
      intersectionObsrv.current = new IntersectionObserver(callbackIntersectionObserver, configs)
      intersectionObsrv.current.observe(bottomList.current)
    }

    return () => {
      if (intersectionObsrv.current) intersectionObsrv.current.disconnect()
    }
  }, [selectedPackage.name])

  useEffect(() => {
    if(isAnnually) setIsShowSaveAnnualy(false)
    else setIsShowSaveAnnualy(true)
  }, [isAnnually])

  const handleChangePackage = (item) => {
    if (item.name.search(/custom/gi) > -1) {
      dispatch({ type: CART_TYPES.SET_CUSTOM_PACKAGE, isCustom: true })
      dispatch({
        type            : CART_TYPES.SET_SELECTED_PACKAGE,
        selectedPackage : { name: 'Custom Plan', id: 'Custom Plan', products: {} }
      })
    } else {
      const productsToObject = item.products.reduce((acc, product) => {
        acc[product.id] = product
        return acc
      }, {})

      dispatch({ type: CART_TYPES.SET_CUSTOM_PACKAGE, isCustom: false })
      dispatch({
        type            : CART_TYPES.SET_SELECTED_PACKAGE,
        selectedPackage : { ...item, products: productsToObject }
      })
    }
  }

  return (
    <Wrapper>
      <WrapperPlanSection>
        <Header>
          <Title>
            { intl.formatMessage({ id: 'de39dfb40' }) }
          </Title>
          <Select
            key={ selectedPackage.name }
            onChange={ handleChangePackage }
            selectedItem={ selectedPackage }
            selectedKey="id"
            options={ [{ name: 'Custom Plan', id: 'Custom Plan' }, ...packages] }
            width="17em"
          />
        </Header>
        <Hr />
        { isCustom && <Text>{ intl.formatMessage({ id: 'd5141717a' }) }</Text> }
        <MobileOnly>
          <Text shaded>{ intl.formatMessage({ id: '60f151225' }) }</Text>
          <Select
            key={ paymentPeriod.selectedPeriod?.name }
            selectedItem={ paymentPeriod.selectedPeriod }
            onChange={ paymentPeriod.handlePaymentPeriodChange }
            options={ paymentPeriod.list }
            isLoading={ paymentPeriod.isLoading }
            placeholder="Select payment period"
          />
          { isShowSaveAnnually && (
            <Text 
              textAlign="center"
              className='save-annually__text'
            >
              { intl.formatMessage({ id: 'ea2f71ac4' }) }
            </Text>
          ) }
          { helpers.hideFeature && (
            <>
              <Text shaded>
                { intl.formatMessage({ id: 'dff00237e' }) }
              </Text>
              <WrapperStorageCapacity>
                <QuantityButton
                  disabled={ !isCustom }
                  total={ selectedPackage?.storage ?? 0 }
                  hideTitle
                  unitName="GB"
                  unitPosition="right"
                />
              </WrapperStorageCapacity>
            </>
          ) }
        </MobileOnly>
        <WrapperCard isCustom={ isCustom }>
          { products.isLoading ? (
            <Loader />
          ) : (
            products.list.map((product) => (
              <ProductCard
                key={ product.id }
                product={ product }
                selectedPackage={ selectedPackage }
                cart={ cart }
                dispatch={ dispatch }
                isCustom={ isCustom }
              />
            ))
          ) }

          { /* this element for triggered to
              stop floating cart section,
              when user used mobile version */ }
          <div ref={ bottomList } />
        </WrapperCard>
      </WrapperPlanSection>
      <YourCart periods={ paymentPeriod } ref={ cartRef } />
    </Wrapper>
  )
}

function ProductCard({ dispatch, selectedPackage, product, cart, isCustom }) {
  const { currency } = cart.detailCurrency
  const intl = useIntl()
  const price = useMemo(() => {
    const data = { annualy: product.price_annualy, monthly: product.price_monthly }

    product.product_prices.forEach((price) => {
      const regex = new RegExp(currency, 'gi')

      if (price.currency.name.search(regex) > -1) {
        data.annualy = price.price_annually
        data.monthly = price.price_monthly
      }
    })

    return cart.isAnnually ? data.annualy : data.monthly
  }, [
    cart.isAnnually,
    currency,
    product.price_annualy,
    product.price_monthly,
    product.product_prices
  ])

  const handleClickProduct = (product) => () => {
    const quantity = product.minimum_order
    dispatch({
      type            : CART_TYPES.SET_SELECTED_PACKAGE,
      selectedPackage : {
        ...selectedPackage,
        products: {
          [product.id]: {
            ...product,
            product_account_package: {
              quantity
            }
          },
          ...selectedPackage.products
        }
      }
    })
  }

  const removeProduct = (productId) => () => {
    dispatch({ type: CART_TYPES.REMOVE_PRODUCT, productId })
  }

  const handleChangeQtyProduct = ({ productId, alias, num, minimum_order }) => {
    if (num === 0) return removeProduct(productId)()
    if (num < minimum_order && USER_SUBSCRIPTION.alias === alias) removeProduct(productId)()
    else dispatch({ type: CART_TYPES.CHANGE_QTY_PRODUCT, num, productId })
  }

  return (
    <Card disabled={ !selectedPackage.products[product.id] && !isCustom }>
      <Card.Title>{ product.product_type.name }</Card.Title>
      <Card.Body>
        <Card.Name>{ product.name }</Card.Name>
        <Card.Text bold>
          { formatMoney(price, { currency }) }
        </Card.Text>
        <Card.Text>
          /{ cart.isAnnually ? 'yr' : 'mo' } per { product.product_unit.name }
        </Card.Text>
        <Card.Text dangerouslySetInnerHTML={{ __html: product.description }} />
        <Card.Gap />
        { selectedPackage.products[product.id] ? (
          <Card.WrapperQtyButton>
            <Card.WrapperTrashIcon onClick={ isCustom ? removeProduct(product.id) : () => {} }>
              <IconTrash disabled={ !isCustom } />
            </Card.WrapperTrashIcon>
            <QuantityButton
              handleChange={ (num) => handleChangeQtyProduct({
                productId     : product.id,
                alias         : product.alias,
                minimum_order : product.minimum_order,
                num
              }) }
              disabled={ !isCustom }
              total={
                (product.product_account_package ? product : selectedPackage.products[product.id])
                  .product_account_package.quantity
              }
            />
          </Card.WrapperQtyButton>
        ) : (
          <Card.WrapperAdd>
            <Button 
              onClick={ handleClickProduct(product) } 
              disabled={ !isCustom } 
              type="warning"
              id={ product.name.split(' ').join('-').toLowerCase()  }
            >
              { intl.formatMessage({ id: 'e34dd330c' }) }
            </Button>
          </Card.WrapperAdd>
        ) }
      </Card.Body>
    </Card>
  )
}

function Loader() {
  return Array(4)
    .fill()
    .map((_, i) => <Shimmer key={ i.toString() } height="100px" />)
}
