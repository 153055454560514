import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useHelpers() {
  const { subTotal, isCustom, selectedPackage, ...cart } = useSelector((state) => state.cart);
  const HIDE_THIS_FEATURES = true;
  const { currency } = cart.detailCurrency;

  const getSavedPrice = useMemo(() => {
    const prices = { monthly: 0, annually: 0 };

    // find amount based on currency
    selectedPackage.account_packages_prices.forEach((price) => {
      const regex = new RegExp(currency, "gi");

      if (price.currency.name.search(regex) > -1) {
        prices.monthly = price.price_monthly || 0;
        prices.annualy = price.price_annually || 0;
      }
    });

    if (!isCustom) return prices.monthly * 12;
    else return cart.subTotalMonthlyForCustom * 12;
  }, [cart.subTotalMonthlyForCustom, currency, isCustom, selectedPackage.account_packages_prices]);

  const showSavedPrice = useMemo(() => {
    const calcSavedPrice = getSavedPrice - subTotal;
    return calcSavedPrice > 0 && cart.isAnnually;
  }, [cart.isAnnually, getSavedPrice, subTotal]);

  return { showSavedPrice, getSavedPrice, hideFeature: !HIDE_THIS_FEATURES };
}
