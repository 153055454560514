import React, { useEffect, useState } from 'react'
import { WrapperQuantity, UnitName, Title, MinButton, Quantity, PlusButton } from './styles'

const QuantityButton = ({
  unitName,
  hideTitle = false,
  unitPosition,
  handleChange = () => {},
  data,
  disabled = false,
  showChangeQuantity = true,
  ...props
}) => {
  const [total, setTotal] = useState(props.total || 0)

  useEffect(() => {
    setTotal(props.total)
  }, [props.total])

  function onClick(type) {
    return () => {
      if (!disabled) {
        setTotal((prevNum) => {
          if (type === 'increase') {
            prevNum += 1
            handleChange(prevNum)
          } else if (type === 'decrease' && prevNum > 0) {
            prevNum -= 1
            handleChange(prevNum)
          }

          return prevNum
        })
      }
    }
  }

  return (
    <WrapperQuantity { ...{ unitPosition, disabled } }>
      { !hideTitle && <Title>Quantity</Title> }
      { showChangeQuantity && (
        <MinButton disabled={ disabled } onClick={ onClick('decrease') }>
          <span>-</span>
        </MinButton>
      ) }
      { showChangeQuantity && (
        <PlusButton disabled={ disabled } onClick={ onClick('increase') }>
          <span>+</span>
        </PlusButton>
      ) }
      <Quantity { ...{ hideTitle } }>{ total }</Quantity>
      <UnitName { ...{ unitPosition } }>{ unitName }</UnitName>
    </WrapperQuantity>
  )
}
export default QuantityButton
