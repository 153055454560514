import React, { useState, forwardRef, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import {
  DesktopOnly,
  MobileOnly,
  Select
}from 'components'
import { useIntl } from 'react-intl'
import { CheckoutBtn } from './components'
import {
  Wrapper,
  WrapperMobile,
  Li,
  Ul,
  Title,
  Hr,
  Text,
  WrapperSavedMoney
} from './styles'
import { formatMoney } from 'utils'
import AuthWall from '../AuthWall'
import { useHelpers } from '../models/useHelpers'

const YourCart = forwardRef(({ periods }, ref) => {
  const intl = useIntl()

  const { token: tokenDashboard } = useSelector((state) => state.tokenDashboard)
  const [showAuthWall, setShowAuthWall] = useState(false)
  const { 
    isAnnually,
    selectedPackage, 
    paymentPeriod, 
    isCustom, 
    ...cart 
  } = useSelector((state) => state.cart)

  const [isShowSaveAnnually, setIsShowSaveAnnualy] = useState(true)

  useEffect(() => {
    if(isAnnually) setIsShowSaveAnnualy(false)
    else setIsShowSaveAnnualy(true)
  }, [isAnnually])

  const keysProduct = Object.keys(selectedPackage.products)
  const helpers = useHelpers()
  const { currency } = cart.detailCurrency
  
  const handleClickCheckout = () => {
    if (tokenDashboard && keysProduct.length) navigate('/checkout')
    else if (!tokenDashboard) setShowAuthWall(true)
  }

  const subTotalText = `${formatMoney(cart.subTotal, { currency })}/${
    isAnnually ? 'yr' : 'mo'
  }`

  return (
    <>
      { showAuthWall && <AuthWall handleClose={ () => setShowAuthWall(false) } /> }
      <Wrapper ref={ ref }>
        <DesktopOnly>
          <Title>{ intl.formatMessage({ id: 'e50000e34' }) }</Title>
          <Hr />
          { 
            keysProduct.length ? (
              <Ul>
                <Li>
                  <Text bold>{ selectedPackage.name }</Text>
                </Li>
                { keysProduct.map((id) => (
                  <Li key={ id }>
                    <Text bold>{ selectedPackage.products[id].name }</Text>
                    <Text>
                      { selectedPackage.products[id]?.product_account_package?.quantity }{ ' ' }
                      { selectedPackage.products[id].product_unit.name }
                    </Text>
                  </Li>
                )) }
              </Ul>
            ) : (
              <Text textAlign='center'>{ intl.formatMessage({ id: '18b8ff5c0' }) }</Text>
            ) 
          }
          <Hr />
          <Text shaded>{ intl.formatMessage({ id: '60f151225' }) }</Text>
          <Select
            key={ periods.selectedPeriod && (periods.selectedPeriod.name === 'Annually' || periods.selectedPeriod.name === 'Monthly') ? intl.formatMessage({ id: `${periods.selectedPeriod.name}-xth` }) : periods.selectedPeriod.name }
            selectedItem={ periods.selectedPeriod }
            onChange={ periods.handlePaymentPeriodChange }
            options={ periods.list }
            isLoading={ periods.isLoading }
            placeholder= { periods.selectedPeriod && (periods.selectedPeriod.name === 'Annually' || periods.selectedPeriod.name === 'Monthly') ? intl.formatMessage({ id: `${periods.selectedPeriod.name}-xth` }) : 'Select payment method' }
          />

          { 
            isShowSaveAnnually
              ? <p className='save-annually__text'>{ intl.formatMessage({ id: 'ea2f71ac4' }) }</p> 
              : null
          } 
          
          <p className='subtotal__text'>
            { intl.formatMessage({ id: '3e88df800' }) }
          </p>
          { 
            !isShowSaveAnnually && (
              <WrapperSavedMoney>
                <Text 
                  bold 
                  fontSize='1.3em' 
                  style={{ textAlign: 'center' }} 
                  as='del'
                >
                  { formatMoney(helpers.getSavedPrice, { currency }) }/{ isAnnually ? 'yr' : 'mo' }
                </Text>
              </WrapperSavedMoney>
            ) 
          }
 
          <p className='subtotal__amount'>
            { subTotalText }
          </p>
          <CheckoutBtn handleClickCheckout={ handleClickCheckout }/>
        </DesktopOnly>

        <MobileOnly>
          <WrapperMobile>
            <div>
              <Text m='0' fontSize='0.9rem'>
                { intl.formatMessage({ id: '810d65461' }) }
              </Text>
              <Text bold fontSize='1.35em' m='0' mt='0.1rem'>
                { subTotalText }
              </Text>
            </div>
            <CheckoutBtn handleClickCheckout={ handleClickCheckout }/>
          </WrapperMobile>
        </MobileOnly>
      </Wrapper>
    </>
  )
})

YourCart.displayName = 'YourCart'

export default YourCart
