import styled from 'styled-components'
import { 
  darkBlueGrey, 
  lightGold, 
  mykonosBlue 
} from 'components/baseColor'
import { 
  IS_FRAME_MOBILE, 
  IS_FRAME_TABLET 
} from 'utils'

export const WrapperCheckout = styled.div`
  margin-top: 1.3em;
  width: 100%;

  ${IS_FRAME_MOBILE} {
    width: unset;
    margin-top: unset;
  }
`

export const Button = styled.button`

  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 4px;
  overflow: hidden;
  padding: 0.5em 2em;
  border: solid 1px ${darkBlueGrey};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: ${(props) => props.width || 'unset'};
  height: ${(props) => props.height || 'unset'};
  background-color: ${lightGold};
  color: ${darkBlueGrey};
  border: solid 1px ${lightGold};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not([disabled]):hover {
    color: #ffffff;
    background-color: ${mykonosBlue};
    border: solid 1px #fff;
  }

  ${IS_FRAME_TABLET} {
    width: 15rem;
    margin-left: auto;
    display: block;
  }

`
